import React from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import Spinner from '@components/spinner/Loading-spinner'
import {CardText} from "reactstrap";

const ProcessOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ProcessOverlayContent = styled.div`
  color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`
const Overlay = () => {
  const {isBlockUI} = useSelector(state => state.general)
  return isBlockUI && (<ProcessOverlay>
    <ProcessOverlayContent>
      <Spinner/>
      <CardText className='mb-0 mt-1 text-white'>Please Wait...</CardText>
    </ProcessOverlayContent>
  </ProcessOverlay>);
};

export default Overlay;