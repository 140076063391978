// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig";

export const generalSlice = createSlice({
  name: "general",
  initialState: {
    isBlockUI: false,
    currentClinicInfo: null,
  },
  reducers: {
    setBlockUI: (state, action) => {
      state.isBlockUI = action.payload;
    },
    showConfirm: (state, action) => {
      state.showConfirm = action.payload;
    },
    updateClinicInfo: (state, action) => {
      state.currentClinicInfo = action.payload;
    },
  },
});

export const { setBlockUI, showConfirm, updateClinicInfo } =
  generalSlice.actions;

export default generalSlice.reducer;
