// api/productApi.js
import axiosClient from "./axios";

class NotifiTemplateApi {
  getNotiProfile = () => {
    const url = "/api/Notifications/profile";
    return axiosClient.get(url);
  };

  setSeenNotiProfile = (id) => {
    const url = `/api/Notifications/set-seen/${id}`;
    return axiosClient.patch(url);
  };

  getTemplateNoti = (params) => {
    if (params.id) {
      const url = `/api/NotificationTemplates/${params.id}`;
      return axiosClient.get(url);
    }
    const url = "/api/NotificationTemplates";
    return axiosClient.get(url, { params });
  };

  createOrUpdate = (data) => {
    if (data.id) {
      const url = `/api/NotificationTemplates/${data.id}`;
      return axiosClient.put(url, data);
    }
    const url = "/api/NotificationTemplates";
    return axiosClient.post(url, data);
  };

  getNotiCampaigns = () => {
    const url = "/api/NotificationCampaigns";
    return axiosClient.get(url);
  };

  deleteTemplateNoti = (ids) => {
    let idsString = "";
    ids.forEach((id) => {
      idsString += "ids=" + id + "&";
    });
    const url = `/api/NotificationTemplates?${idsString}`;
    return axiosClient.delete(url);
  };

  sendNoti = (data) => {
    const url = "/api/Notifications/send-notification";
    return axiosClient.post(url, data);
  };
}

const notifiTemplateApi = new NotifiTemplateApi();
export default notifiTemplateApi;
