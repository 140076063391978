// ** Redux Imports
import { createSelector, createSlice } from "@reduxjs/toolkit";
const getSlugs = (routes) => {
  const res = [];
  for (let i = 0; i < routes.length; i++) {
    const tmp = { ...routes[i] };
    if (tmp.modules && tmp.modules.length > 0) {
      tmp.modules = getSlugs(tmp.modules);
      for (let index = 0; index < tmp.modules.length; index++) {
        res.push(
          tmp.modules[index].toLowerCase?.().trim?.().replaceAll?.(/\s/g, "")
        );
      }
    } else {
      res.push(tmp.slug.toLowerCase?.().trim?.().replaceAll?.(/\s/g, ""));
    }
  }
  return res;
};

export const hasPermission = (route, permission) => {
  if (route.meta && route.meta.permissions) {
    const permissionsRoute = route.meta.permissions.replaceAll?.(/\s/g, "");
    return permission.includes(permissionsRoute);
  } else {
    return true;
  }
};

export const permissionSlice = createSlice({
  name: "permission",
  initialState: {
    roles: [],
    availableRoutes: [],

    permission: [],
  },
  reducers: {
    handleRoles: (state, action) => {
      state.roles = action.payload;
    },

    generateRoutes: (state, action) => {
      state.permission = getSlugs([...action.payload]);

      state.availableRoutes = action.payload;
    },
  },
});

export const { handleRoles, generateRoutes } = permissionSlice.actions;

export const routerSelector = createSelector(
  (state) => state.permission,
  (permission) => {
    return permission.routes;
  }
);
export const permissionSelector = createSelector(
  (state) => state.permission,
  (permission) => {
    return permission.permission;
  }
);

export default permissionSlice.reducer;
