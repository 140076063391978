// ** React Imports
import "@styles/react/libs/flatpickr/flatpickr.scss";
// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";
import "@styles/react/libs/react-select/_react-select.scss";
import "@styles/react/libs/tables/react-dataTable-component.scss";

import "@styles/react/pages/page-form-validation.scss";

import "cleave.js/dist/addons/cleave-phone.us";
import "@styles/react/libs/input-number/input-number.scss";
// ** PrismJS
import "prismjs";
import "prismjs/components/prism-jsx.min";
import "prismjs/themes/prism-tomorrow.css";

import { lazy, Suspense } from "react";

import ReactDOM from "react-dom";
// ** React Editor
import "@styles/react/libs/editor/editor.scss";
// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
// ** Redux Imports
import { Provider } from "react-redux";
// ** i18n
import "./configs/i18n";
// ** Intl & ThemeColors Context
import { ToastContainer } from "react-toastify";
// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
// ** Ripple Button
import "./@core/components/ripple-button";
// ** Spinner (Splash Screen
import "./@core/scss/core.scss";
// ** Fake Database
import "./@fake-db";
import "./assets/scss/style.scss";
import { store } from "./redux/store";
// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { ThemeContext } from "./utility/context/ThemeColors";
import UiUtility from "./views/ui-kit/UiUtility";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={null}>
      <ThemeContext>
        <LazyApp />
        <ToastContainer newestOnTop />
      </ThemeContext>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
