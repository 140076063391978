// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import calendar from "./calendar";
import general from "./general";
import permission from "./permission";
import noti from "./notication";

const rootReducer = {
  auth,
  navbar,
  layout,
  calendar,
  general,
  permission,
  noti,
};

export default rootReducer;
