import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import NotifiTemplateApi from "@src/api/noti";

export const fetchNoti = createAsyncThunk(
  "appNoti/fetchNoti",
  async (param, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const response = await NotifiTemplateApi.getNotiProfile();
    dispatch(setNotiData(response));
    return response;
  }
);

export const handleReadNoti = createAsyncThunk(
  "appNoti/handleReadNoti",
  async (id, thunkAPI) => {
    const response = await NotifiTemplateApi.setSeenNotiProfile(id);

    return response;
  }
);

export const notiSlice = createSlice({
  name: "NotiProfile",
  initialState: {
    notifications: [],
    notificationCount: 0,
    notiUnread: 0,
    drawer: false,
    notiData: {},
  },
  reducers: {
    setNotiData: (state, action) => {
      state.notifications = action.payload.data;
      state.notificationCount = action.payload.totalCount;
      state.notiUnread = action.payload.data?.filter?.(
        (noti) => !Boolean(noti.isSeen)
      )?.length;
    },
    toogleDrawerNoti: (state, action) => {
      const { isOpen, data } = action.payload;

      state.drawer = isOpen;
      state.notiData = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleReadNoti.fulfilled, (state, action) => {
      const newList = [...state.notifications];
      const index = newList.findIndex(
        (item) => item.id === action.payload.data.id
      );
      // state.notifications = action.payload.data;
      if (index !== -1) {
        newList[index] = action.payload.data;
      }
      state.notifications = newList;
      state.notiUnread = newList?.filter?.(
        (noti) => !Boolean(noti.isSeen)
      )?.length;
    });
  },
});

export const { toogleDrawerNoti, setNotiData } = notiSlice.actions;

export const notiListSelector = createSelector(
  (state) => state.noti,
  (noti) => noti.notifications
);

export const notiUnreadSelector = createSelector(
  (state) => state.noti,
  (noti) => noti.notiUnread
);

export const NotiDrawerSelector = createSelector(
  (state) => state.noti,
  (noti) => ({ openDrawer: noti.drawer, notiData: noti.notiData })
);
export default notiSlice.reducer;
