import axios from "axios";
import queryString from "query-string";
// Set up default config for http requests here

// Please have a look at here `https://github.com/axios/axios#request-

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "application/json",
  },
  paramsSerializer: (params) => queryString.stringify(params),
});
axiosClient.interceptors.request.use(async (config) => {
  const accessToken = getToken();
  const clinicInfo = JSON.parse(localStorage.getItem("currentClinic"));
  // ** If token is present add it to request's Authorization Header
  if (accessToken) {
    // ** eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers.clinicId = clinicInfo?.id;
  }
  config.headers.platform = `web`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    const { config, response } = error;

    // toast.error(<BaseToast
    //   title={response?.data?.message}
    // />);

    const originalRequest = config;
    if (response && response.status === 401) {
      // temp close
      // window.location = "/login"
    }
    return Promise.reject(response);
  }
);

function getToken() {
  return localStorage.getItem("accessToken");
}

export default axiosClient;
